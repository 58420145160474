#photo-deck {
  /* background: lightblue; */

  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

#photo-deck > div {
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

#photo-deck > div > div {
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  will-change: transform;
  border: 1em solid #fff;
  border-bottom: 4em solid #fff;
  border-radius: 3px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
  cursor: grab;
}
