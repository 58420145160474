.header-color {
  background: #0b486b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f56217,
    #0b486b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f56217,
    #0b486b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid {
  background: #0b486b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f56217,
    #0b486b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f56217,
    #0b486b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Oxygen", sans-serif;
}

.profile-pic {
  height: 250px;
  padding-top: 5em;
}

.banner-text {
  background-color: black;
  opacity: 0.8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  margin-bottom: 0px;
  font-size: 66px;
  font-weight: bold;
  color: white;
  font-family: "Oxygen", sans-serif;
}

.banner-text h2 {
  margin-top: 0px;
  font-size: 25px;
  font-weight: bold;
  color: white;
  font-family: "Oxygen", sans-serif;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* project page css */
.projects-grid {
  display: flex;
}

/* contact page css */
.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: "Anton", sans-serif;
}

.contact-grid p {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
  text-align: justify;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-grid i {
  font-size: 30px;
  padding-right: 2rem;
}

/* contact page css */
.resume-right-col {
  background: #27221f;
  opacity: 0.8;
  box-shadow: 15px 35px 8px 28px #888888;
  color: white;
  padding: 2em;
}

.publications-col {
  background: #27221f;
  opacity: 0.8;
  box-shadow: 30px 20px 15px 0px #888888;
  color: white;
  padding: 2em;
}

.about-container {
  font-family: "Oxygen", sans-serif;
}

.about-container h1 {
  font-family: "Oxygen", sans-serif;
}

.about-title h1 {
  text-align: center;
  font-family: "Oxygen", sans-serif;
}

.about-title h3 {
  text-align: center;
  font-family: "Oxygen", sans-serif;
}

.about-cell-2 {
  margin: "auto";
  padding-right: "10em";
  padding-left: "10em";
}

@media screen and (max-width: 640px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
  }

  .about-cell-1 {
    width: 100%;
  }

  .about-cell-2 {
    padding: 0px;
    width: 100%;
  }

  .about-cell-2 p {
    padding: 0px;
    width: 100%;
  }
}
